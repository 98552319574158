:global {

  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
  }
}

.container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  --car-position: 0;
  --smoke-visibility: hidden;
  --smoke-opacity: 0;
}

.car-horizontal {
  position: absolute;
  top: 28vh;
  /* Adjust vertical start position */
  left: 0;
  width: 40vw;
  height: 40vw;
  /* Moves from right off-screen to left off-screen */
  animation: drive 6s linear infinite;
}

/* CAR CONTAINER */
.car-container {
  position: absolute;
  margin-top: 28vh;
  /* was 250px */
  margin-left: 20vw;
  /* was 200px */
  width: 20vw;
  /* was 400px */
  height: 20vw;
  animation: bounce 2s infinite;
  left: var(--car-position);
}

@keyframes drive {
  0% {
    transform: translateX(100vw);
  }

  100% {
    transform: translateX(-40vw);
  }
}

@keyframes bounce {

  0%,
  100% {
    margin-top: -10vh;
  }

  25% {
    margin-top: -9vh;
  }

  50% {
    margin-top: -9vh;
  }

  75% {
    margin-top: -10vh;
  }

  90% {
    margin-top: -9vh;
  }

  100% {
    margin-top: -10vh;
  }
}

/* CAR BODY */
.car-body {
  height: 20vw;
  /* was 200px */
  width: 38vw;
  /* was 380px */
  left: 10vw;
  /* was 100px */
  background-color: #222222;
  border-top-left-radius: 20vw;
  border-top-right-radius: 30vw;
  position: absolute;
  border-left: 0.6vw solid transparent;
  border-right: 0.6vw solid black;
  border-top: 0.6vw solid black;
  border-bottom: 0.6vw solid black;
}

/* CAR HOOD */
.car-hood {
  position: absolute;
  height: 12vw;
  /* was 120px */
  width: 12vw;
  background-color: #222222;
  top: 8vw;
  /* was 80px */
  border-left: 0.6vw solid transparent;
  border-right: 0.6vw solid transparent;
  border-top: 0.6vw solid transparent;
  border-bottom: 0.6vw solid black;
  border-top-left-radius: 30vw;
  z-index: 3;
}

/* HOOD DOOR */
.hood-door {
  position: absolute;
  height: 12.5vw;
  width: 4.5vw;
  background-color: transparent;
  top: 1.9vw;
  left: 39vw;
  border-left: 0.7vw solid black;
  border-bottom: 0.6vw solid black;
  transform: skew(30deg);
  z-index: 3;
}

/* HOOD DOOR LINE */
.hood-door-line {
  position: absolute;
  height: 8vw;
  width: 4.5vw;
  background-color: transparent;
  top: 1vw;
  left: 0;
  border-bottom: 0.6vw solid black;
}

.hood-door-line:nth-child(2) {
  position: absolute;
  height: 8vw;
  width: 3.5vw;
  background-color: transparent;
  top: -0.5vw;
  left: 0;
  border-bottom: 0.6vw solid black;
}

/* CAR FONT BUMP */
.car-font-bump {
  position: absolute;
  height: 4vw;
  width: 8vw;
  background-color: #222222;
  top: 9vw;
  left: 1.5vw;
  border-top-left-radius: 30vw;
  border: 0.6vw solid black;
}

/* CAR HEADLIGHT */
.car-headlight {
  position: absolute;
  height: 3vw;
  width: 1.5vw;
  background-color: yellow;
  top: 15vw;
  border-radius: 100px;
  border: 0.6vw solid black;
  left: -1.2vw;
}

/* CAR FRONT WINDOW */
.car-front-window {
  height: 18vw;
  width: 25vw;
  left: 10.8vw;
  top: 1.5vw;
  background-color: white;
  border-top-left-radius: 13.5vw;
  border-top-right-radius: 10vw;
  position: absolute;
  border: 0.6vw solid black;
  background: rgba(255, 255, 255, 0.25);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 2;
}

/* FRONT SEAT */
.front-seat {
  position: absolute;
  left: 22.5vw;
  top: 4.5vw;
  width: 1vw;
  height: 3.3vw;
  transform: rotate(10deg);
  border-top: 0.6vw solid black;
  border-right: 0.6vw solid black;
  border-left: 0.6vw solid black;
  border-bottom: 0.6vw solid black;
  border-top-right-radius: 1vw;
  border-top-left-radius: 1vw;
}

/* GIRL DRIVER */
.girl-driver {
  position: absolute;
  width: 5vw;
  height: 10vw;
  background-color: transparent;
  top: 4vw;
  left: 20vw;
}

.girl-driver .head {
  position: absolute;
  width: 3vw;
  height: 3vw;
  background-color: black;
  border-radius: 50%;
  right: 2vw;
  bottom: 8vw;
}

.girl-driver .body {
  position: absolute;
  width: 0.4vw;
  height: 3vw;
  background-color: black;
  top: 2vw;
  left: 1.3vw;
}

.girl-driver .arm-left,
.girl-driver .arm-right {
  position: absolute;
  width: 5vw;
  height: 0.4vw;
  background-color: black;
  top: 3.5vw;
  transform-origin: left;
}

.girl-driver .arm-left {
  left: 1.2vw;
  transform: rotate(185deg);
}

.girl-driver .arm-right {
  left: 1.5vw;
  transform: rotate(190deg);
}

.girl-driver .hair {
  position: absolute;
  width: 2.8vw;
  height: 1.2vw;
  background-color: cyan;
  border-radius: 80%;
  top: -1vw;
  left: 0.2vw;
  z-index: 1;
}

.girl-driver .long-hair {
  position: absolute;
  width: 4vw;
  height: 6vw;
  background-color: cyan;
  border-radius: 2vw;
  top: 1.5vw;
  left: -0.5vw;
  z-index: -1;
}

.girl-driver .long-hair-layer {
  position: absolute;
  width: 2vw;
  height: 3vw;
  background-color: cyan;
  border-radius: 2vw;
  top: 0.5vw;
  left: 1.2vw;
  opacity: 0.9;
}

/* STEERING WHEEL */
.steering-wheel {
  position: absolute;
  height: 0.8vw;
  width: 2vw;
  background-color: transparent;
  top: 6.2vw;
  border-left: transparent;
  border-right: transparent;
  border-top: 0.6vw solid transparent;
  border-bottom: 0.6vw solid black;
  left: 16vw;
  transform: rotate(80deg);
}

/* REAR VIEW MIRROR */
.rear-view-mirror {
  position: absolute;
  height: 0.8vw;
  width: 1vw;
  background-color: transparent;
  top: 2.9vw;
  border-left: transparent;
  border-right: transparent;
  border-top: 0.6vw solid transparent;
  border-bottom: 0.6vw solid black;
  left: 18.5vw;
  transform: rotate(90deg);
}

/* CAR BACK WINDOW DIVIDER */
.car-back-window-divider {
  position: absolute;
  height: 0.8vw;
  width: 4.3vw;
  background-color: transparent;
  top: 5.2vw;
  border-left: transparent;
  border-right: transparent;
  border-top: 0.6vw solid transparent;
  border-bottom: 0.6vw solid black;
  left: 31.5vw;
  transform: rotate(100deg);
  z-index: 3;
}

/* CAR FONT WINDOW MASK */
.car-font-window-mask {
  position: absolute;
  height: 12vw;
  width: 37vw;
  background-color: #222222;
  top: 8vw;
  left: -0.5vw;
  border-top-left-radius: 30vw;
  border-bottom-left-radius: 2vw;
  border-left: 0.6vw solid black;
  border-top: 0.6vw solid black;
  border-bottom: 0.6vw solid black;
  z-index: 3;
}

/* CAR WINDOW DIVIDER */
.car-window-divider {
  position: absolute;
  height: 7.1vw;
  width: 1.5vw;
  background-color: #222222;
  top: 1.5vw;
  left: 25vw;
  border-left: 0.6vw solid black;
  border-right: 0.6vw solid black;
  z-index: 3;
}

/* Divider Two (left line of the car's front door) */
.divider-two {
  position: absolute;
  height: 8.5vw;
  width: 1.5vw;
  background-color: #222222;
  top: 10vw;
  left: 25vw;
  border-left: 0.6vw solid black;
  border-right: 0.6vw solid transparent;
  z-index: 3;
}

/* BEHIND SHAPE (CAR DOOR) */
.car-door {
  position: absolute;
  height: 6vw;
  width: 12.6vw;
  background-color: transparent;
  top: 12.5vw;
  left: 12.4vw;
  border-left: 0.6vw solid black;
  border-bottom: 0.6vw solid black;
  border-bottom-left-radius: 2.5vw;
  z-index: 3;
}

/* FRONT SHAPE (CAR DOOR TWO) */
.car-door-two {
  position: absolute;
  height: 10.2vw;
  width: 12vw;
  background-color: transparent;
  top: 8vw;
  left: 12.4vw;
  border: 0 solid transparent;
  border-top: 0.6vw solid black;
  z-index: 3;
}

/* CAR DOOR THREE */
.car-door-three {
  position: absolute;
  height: 10.2vw;
  width: 10vw;
  background-color: transparent;
  top: 8vw;
  left: 27.1vw;
  border: 0 solid transparent;
  border-top: 0.6vw solid black;
  z-index: 3;
}

/* FRONT WHEEL */
.front-wheel {
  position: absolute;
  height: 9vw;
  width: 9vw;
  left: 3vw;
  border-radius: 100px;
  background-color: white;
  top: 15vw;
  border: 0.6vw solid black;
  animation: roll 0.5s linear infinite;
  z-index: 3;
}

@keyframes roll {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* RIM */
.rim {
  height: 3.5vw;
  width: 3.5vw;
  margin: auto;
  margin-top: 2.2vw;
  border-radius: 100px;
  background-color: transparent;
  border: 0.6vw solid black;
  z-index: 3;
}

/* WHEEL TWO */
.wheel-two {
  left: 33vw;
}

/* TIRE LINE */
.tire-line {
  position: absolute;
  height: 7vw;
  width: 7vw;
  left: 0.8vw;
  border-radius: 200px;
  top: 0.5vw;
  border-left: 0.6vw solid gray;
  border-right: 0.6vw solid transparent;
  border-top: 0.6vw solid transparent;
  border-bottom: 0.6vw solid transparent;
}

/* CAR EXHAUST */
.car-exhaust {
  position: absolute;
  height: 1.5vw;
  width: 4.5vw;
  background-color: white;
  top: 20.6vw;
  border-radius: 100px;
  border: 0.6vw solid black;
  left: 45vw;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* CAR MIRROR */
.car-mirror {
  position: absolute;
  height: 1.8vw;
  width: 1.5vw;
  background-color: white;
  top: 7vw;
  border-radius: 100px;
  border: 0.6vw solid black;
  left: 11.5vw;
  z-index: 3;
}

/* GAS PUMP */
.gas-pump {
  position: absolute;
  height: 1.5vw;
  width: 1.5vw;
  background-color: #222222;
  top: 10vw;
  border-radius: 100px;
  border: 0.6vw solid black;
  left: 34vw;
  z-index: 3;
}

/* GAS PUMP LINES */
.gas-pump-lines {
  position: absolute;
  height: 0.8vw;
  width: 1.5vw;
  background-color: transparent;
  top: 10.3vw;
  border-left: transparent;
  border-right: transparent;
  border-top: 0.6vw solid black;
  border-bottom: 0.6vw solid black;
  left: 33.1vw;
  z-index: 3;
}

/* FRONT LINE */
.front-line {
  position: absolute;
  height: 0.8vw;
  width: 2.5vw;
  background-color: transparent;
  top: 10.3vw;
  border-left: transparent;
  border-right: transparent;
  border-top: 0.6vw solid transparent;
  border-bottom: 0.6vw solid black;
  left: 4vw;
  transform: rotate(33deg);
  z-index: 3;
}

/* FRONT LINE TWO */
.front-line-two {
  position: absolute;
  height: 0.8vw;
  width: 1.5vw;
  background-color: transparent;
  top: 11.8vw;
  border-left: transparent;
  border-right: transparent;
  border-top: 0.6vw solid transparent;
  border-bottom: 0.6vw solid black;
  left: 2.7vw;
  transform: rotate(33deg);
  z-index: 3;
}

/* FRONT LINE THREE */
.front-line-three {
  position: absolute;
  height: 0.8vw;
  width: 1.5vw;
  background-color: transparent;
  top: 9vw;
  border-left: transparent;
  border-right: transparent;
  border-top: 0.6vw solid transparent;
  border-bottom: 0.6vw solid black;
  left: 6vw;
  transform: rotate(33deg);
  z-index: 3;
}

/* TRUNK TIRE */
.trunk-tire {
  position: absolute;
  width: 2.5vw;
  height: 7.5vw;
  top: 5vw;
  left: 44.9vw;
  background-color: white;
  border: 0.6vw solid black;
  border-radius: 5px;
  transform: rotate(-29deg);
}

/* TAIL LIGHT */
.tail-light {
  position: absolute;
  height: 2.7vw;
  width: 1.8vw;
  background-color: red;
  top: 15.5vw;
  border-radius: 100px;
  border-top-right-radius: 30vw;
  border-bottom-right-radius: 0;
  border-right: 0.6vw solid black;
  border-left: 0.6vw solid black;
  border-top: 0.6vw solid black;
  border-bottom: 0.6vw solid black;
  left: 45.9vw;
}

/* DOOR HANDLE */
.door-handle {
  width: 2vw;
  border-top: 0.6vw solid black;
  position: absolute;
  top: 11vw;
  left: 22vw;
  z-index: 3;
}

/* CAR RACK */
.car-rack {
  position: absolute;
  height: 1.8vw;
  width: 15vw;
  background-color: transparent;
  top: -2vw;
  border-bottom: 0.6vw solid black;
  left: 19vw;
}

/* RACK LINE LEFT */
.rack-line-left {
  position: absolute;
  height: 2.2vw;
  width: 2vw;
  background-color: transparent;
  top: -2.4vw;
  border-left: 0.6vw solid black;
  border-bottom: 0.6vw solid transparent;
  left: 20vw;
  z-index: 3;
}

/* RACK LINE RIGHT */
.rack-line-right {
  position: absolute;
  height: 2.2vw;
  width: 2vw;
  background-color: transparent;
  top: -2.4vw;
  border-left: 0.6vw solid black;
  border-bottom: 0.6vw solid transparent;
  left: 32.4vw;
}

/* RACK LINE CENTER */
.rack-line-center {
  position: absolute;
  height: 2.2vw;
  width: 2vw;
  background-color: transparent;
  top: -2.4vw;
  border-left: 0.6vw solid black;
  border-bottom: 0.6vw solid transparent;
  left: 26vw;
}

/* RACK LEVEL TWO */
.rack-level-two {
  position: absolute;
  background-color: transparent;
  top: -3.5vw;
}

/* CASE */
.case {
  width: 3vw;
  height: 4vw;
  top: -4.8vw;
  background-color: #C0F8C6;
  left: 20.7vw;
  position: absolute;
  border: 0.6vw solid black;
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
  animation: bouncetwo 2s linear infinite;
}

@keyframes bouncetwo {
  0% {
    top: -5.7vw;
  }

  25% {
    top: -4.8vw;
  }

  50% {
    top: -4.8vw;
  }

  75% {
    top: -5.5vw;
  }

  90% {
    top: -4.8vw;
  }

  100% {
    top: -5.7vw;
  }
}

/* CASE TWO */
.casetwo {
  width: 6vw;
  height: 5vw;
  top: -6vw;
  background-color: gray;
  left: 25vw;
  position: absolute;
  border: 0.6vw solid black;
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
  animation: bouncethree 2s linear infinite;
}

@keyframes bouncethree {
  0% {
    top: -7vw;
  }

  25% {
    top: -6vw;
  }

  50% {
    top: -6vw;
  }

  75% {
    top: -6.5vw;
  }

  90% {
    top: -6vw;
  }

  100% {
    top: -7vw;
  }
}

/* SPEED LINES */
.speed-line-one {
  position: absolute;
  height: 0.8vw;
  width: 5.5vw;
  background-color: transparent;
  top: 27vw;
  border-left: transparent;
  border-right: transparent;
  border-top: 0.6vw solid transparent;
  border-bottom: 0.6vw solid black;
  left: 72.5vw;
  animation: speed 1.5s ease infinite;
}

.speed-line-two {
  position: absolute;
  height: 0.8vw;
  width: 3.5vw;
  background-color: transparent;
  top: 28vw;
  border-left: transparent;
  border-right: transparent;
  border-top: 0.6vw solid transparent;
  border-bottom: 0.6vw solid black;
  left: 72.5vw;
  animation: speed 1.5s ease infinite;
  animation-delay: 0.3s;
}

.speed-line-three {
  position: absolute;
  height: 0.8vw;
  width: 7.5vw;
  background-color: transparent;
  top: 29vw;
  border-left: transparent;
  border-right: transparent;
  border-top: 0.6vw solid transparent;
  border-bottom: 0.6vw solid black;
  left: 72.5vw;
  animation: speed 1.5s ease infinite;
  animation-delay: 0.6s;
  display: none;
}

.speed-line-four {
  position: absolute;
  height: 0.8vw;
  width: 2vw;
  background-color: transparent;
  top: 30vw;
  border-left: transparent;
  border-right: transparent;
  border-top: 0.6vw solid transparent;
  border-bottom: 0.6vw solid black;
  left: 72.5vw;
  animation: speed 1.5s ease infinite;
  animation-delay: 0.3s;
  display: none;
}

@keyframes speed {
  0% {
    left: 72.5vw;
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  100% {
    left: 80vw;
    opacity: 0;
  }
}

.speed-lines {
  top: 8vw;
  position: absolute;
}

/* SKY */
.sky {
  position: absolute;
  width: 100%;
  height: 37%;
  background-color: #87CEEB;
  background: linear-gradient(to bottom, #87CEEB, #B0E0E6);
  top: 0;
  left: 0;
}

/* CLOUD */
.cloud {
  position: absolute;
  width: 15vw;
  height: 8vw;
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 2vw rgba(0, 0, 0, 0.1);
  top: 5vw;
  opacity: 0.8;
  animation: moveCloud 15s linear infinite;
}

.cloud::before,
.cloud::after {
  content: '';
  position: absolute;
  background: white;
  border-radius: 50%;
}

.cloud::before {
  width: 10vw;
  height: 7vw;
  top: -2vw;
  left: -5vw;
}

.cloud::after {
  width: 12vw;
  height: 9vw;
  top: -1vw;
  left: 8vw;
}

/* SUN */
.sun {
  position: absolute;
  width: 8vw;
  height: 8vw;
  background: radial-gradient(circle, #ffdd55, #ffcc00 70%, #ffcc00 100%);
  border-radius: 50%;
  top: 5vh;
  left: 80vw;
  box-shadow: 0 0 2vw rgba(255, 223, 0, 0.6), 0 0 4vw rgba(255, 223, 0, 0.4);
}

@keyframes moveCloud {
  0% {
    left: -20vw;
  }

  100% {
    left: 100%;
  }
}

/* ROAD */
.road {
  position: absolute;
  width: 100%;
  height: 35vh;
  top: 37%;
  background: repeating-linear-gradient(0deg, #535353, #535353 5vw, #444 10vw, #444 18vw),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.2), transparent 100%);
  box-shadow: inset 0 0.2vw 0.5vw rgba(0, 0, 0, 0.3);
  z-index: -1;
}

/* SIDE ROAD */
.sideRoad {
  position: absolute;
  width: 100%;
  height: 28vh;
  top: 72%;
  background-color: #217221;
  z-index: -1;
  overflow: hidden;
}

/* GRASS */
.grass {
  position: absolute;
  width: 0.5vw;
  height: 5vh;
  background: linear-gradient(to top, #4CAF50, #81C784);
  transform-origin: bottom center;
  transform: rotate(-10deg);
  animation: sway 3s infinite ease-in-out;
  clip-path: polygon(50% 0%, 60% 25%, 70% 50%, 60% 100%, 40% 100%, 30% 50%, 40% 25%);
}

.grass:nth-child(2n) {
  width: 0.6vw;
  height: 4.5vh;
  background: linear-gradient(to top, #388E3C, #66BB6A);
  transform: rotate(10deg);
  animation-delay: 0.2s;
}

.grass:nth-child(3n) {
  width: 0.4vw;
  height: 5.5vh;
  background: linear-gradient(to top, #2E7D32, #66BB6A);
  transform: rotate(-15deg);
  animation-delay: 0.4s;
}

.grass::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 1vh;
  background: radial-gradient(circle, #81C784, transparent);
  border-radius: 50%;
  z-index: -1;
}

.grass-shadow {
  position: absolute;
  width: 0.5vw;
  height: 1vh;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.3), transparent);
  bottom: 0;
  left: 0;
  transform: translateY(10%);
  z-index: -1;
}

/* FLOWER */
.flower-container {
  position: absolute;
  width: 100%;
  height: 20%;
  bottom: 5%;
  overflow: hidden;
}

.flower {
  position: absolute;
  width: 2vw;
  height: 2vw;
  animation: swayFlower 5s infinite ease-in-out;
}

.flower-petal {
  position: absolute;
  width: 1vw;
  height: 1.5vw;
  background: radial-gradient(circle, #FF4081, #E91E63);
  border-radius: 50%;
  transform-origin: bottom center;
}

.flower-petal:nth-child(1) {
  transform: rotate(0deg);
}

.flower-petal:nth-child(2) {
  transform: rotate(45deg);
}

.flower-petal:nth-child(3) {
  transform: rotate(90deg);
}

.flower-petal:nth-child(4) {
  transform: rotate(135deg);
}

.flower-petal:nth-child(5) {
  transform: rotate(180deg);
}

.flower-petal:nth-child(6) {
  transform: rotate(225deg);
}

.flower-petal:nth-child(7) {
  transform: rotate(270deg);
}

.flower-petal:nth-child(8) {
  transform: rotate(315deg);
}

.flower-center {
  position: absolute;
  width: 1vw;
  height: 1vw;
  background: radial-gradient(circle, #FFEB3B, #FBC02D);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0.3vw rgba(0, 0, 0, 0.2);
}

.flower-stem {
  position: absolute;
  width: 0.2vw;
  height: 5vw;
  background: linear-gradient(to top, #4CAF50, #66BB6A);
  border-radius: 0.2vw;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

@keyframes swayFlower {

  0%,
  100% {
    transform: translateX(0) rotate(0deg);
  }

  50% {
    transform: translateX(1vw) rotate(2deg);
  }
}

/* ROCK */
.rock {
  position: absolute;
  width: 2vw;
  height: 1.5vh;
  background: linear-gradient(to bottom right, #8b8b8b, #616161);
  border-radius: 50%;
  box-shadow: inset -0.2vw -0.2vh 0.5vw rgba(0, 0, 0, 0.4),
    inset 0.2vw 0.2vh 0.5vw rgba(255, 255, 255, 0.2);
}

.rock::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 2px;
  background: #424242;
  top: 50%;
  left: 25%;
  transform: rotate(-20deg);
}

.rock-crack {
  position: absolute;
  width: 1.5vw;
  height: 0.1vh;
  top: 50%;
  left: 10%;
  transform: rotate(-15deg);
  border-radius: 50%;
  background: linear-gradient(to bottom right, #8b8b8b, #616161);
  box-shadow: inset 0 0 0.5vw rgba(0, 0, 0, 0.5);
}

.rock:nth-child(2n) {
  width: 2.5vw;
  height: 1.2vh;
  background: linear-gradient(to bottom right, #9e9e9e, #757575);
}

.rock:nth-child(3n) {
  width: 1.8vw;
  height: 1.8vh;
  background: linear-gradient(to bottom right, #757575, #616161);
  box-shadow: inset -0.1vw -0.1vh 0.3vw rgba(0, 0, 0, 0.5),
    inset 0.1vw 0.1vh 0.3vw rgba(255, 255, 255, 0.2);
}

/* ANIMATION FOR GRASS BLADES */
@keyframes sway {

  0%,
  100% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(10deg);
  }
}

/* GROUND */
.ground {
  position: absolute;
  width: 100%;
  top: 37%;
  border-top: 0.6vw solid black;
}

.ground-two {
  position: absolute;
  width: 100%;
  top: 70%;
  border-top: 0.6vw solid black;
}

.ground-three {
  position: absolute;
  width: 100%;
  top: 72%;
  border-top: 0.6vw solid black;
}

.ground-four {
  position: absolute;
  width: 100%;
  top: 39%;
  border-top: 0.6vw solid black;
}

/* TREE */
.tree-trunk {
  position: absolute;
  width: 1vw;
  top: 15.8vw;
  left: 6.5vw;
  height: 14.7vw;
  border: 0.3vw solid black;
  background: linear-gradient(to bottom, #8B4513, #A0522D);
  box-shadow: inset 0 0 1vw rgba(0, 0, 0, 0.2);
}

.leaves {
  background: radial-gradient(circle, #4CAF50, #2E7D32);
  width: 8vw;
  height: 9vw;
  top: 4.5vw;
  left: 2.5vw;
  position: absolute;
  text-align: center;
  border: 0.6vw solid black;
  border-radius: 100%;
}

.leaves_two {
  background: green;
  width: 10vw;
  height: 8vw;
  left: 1.5vw;
  top: 10vw;
  position: absolute;
  text-align: center;
  border: 0.6vw solid black;
  border-radius: 100%;
}

.leaves_three {
  background: green;
  width: 8vw;
  height: 11vw;
  top: 2vw;
  left: 6vw;
  position: absolute;
  text-align: center;
  border: 0.6vw solid black;
  border-radius: 100%;
}

.leaves_four {
  background: green;
  width: 8vw;
  height: 9vw;
  top: 6vw;
  left: 7vw;
  position: absolute;
  text-align: center;
  border: 0.6vw solid black;
  border-radius: 100%;
}

.leaves_five {
  background: green;
  width: 8vw;
  height: 10vw;
  top: 3vw;
  left: -1vw;
  position: absolute;
  text-align: center;
  border: 0.6vw solid black;
  border-radius: 12.5vw;
}

.berry {
  position: absolute;
  width: 0.8vw;
  height: 0.8vw;
  background-color: red;
  top: 15vw;
  left: 8vw;
  border-radius: 10px;
}

.berry-two {
  position: absolute;
  width: 0.8vw;
  height: 0.8vw;
  background-color: red;
  top: 8vw;
  left: 5vw;
  border-radius: 10px;
}

.berry-three {
  position: absolute;
  width: 0.8vw;
  height: 0.8vw;
  background-color: red;
  top: 9vw;
  left: 1vw;
  border-radius: 10px;
}

.berry-four {
  position: absolute;
  width: 0.8vw;
  height: 0.8vw;
  background-color: red;
  top: 3.5vw;
  left: 10vw;
  border-radius: 10px;
}

.berry-five {
  position: absolute;
  width: 0.8vw;
  height: 0.8vw;
  background-color: red;
  top: 11vw;
  left: 12.5vw;
  border-radius: 10px;
}

.berry-six {
  position: absolute;
  width: 0.8vw;
  height: 0.8vw;
  background-color: red;
  top: 13vw;
  left: 4.5vw;
  border-radius: 10px;
}

.tree {
  position: absolute;
  top: -8vw;
  left: 9vw;
  animation: tree-move 3s linear infinite;
}

@keyframes tree-move {
  0% {
    left: -20vw;
  }

  100% {
    left: 100vw;
  }
}

/* STREET LIGHT */
.street-light {
  position: absolute;
  top: -8vw;
  left: -10vw;
  animation: tree-move 3s linear infinite;
  animation-delay: 0.5s;
}

.street-light-poll {
  position: absolute;
  width: 0.5vw;
  top: 16.1vw;
  left: 6.7vw;
  height: 14.7vw;
  border: 0.6vw solid black;
  background-color: #C0F8C6;
}

.light {
  position: absolute;
  width: 2vw;
  top: 13.5vw;
  left: 6vw;
  height: 2vw;
  border: 0.6vw solid black;
  border-radius: 100%;
  background-color: yellow;
}

.street-light-top {
  position: absolute;
  background-color: #C0F8C6;
  width: 2.8vw;
  top: 12.5vw;
  left: 5.6vw;
  height: 0.8vw;
  border: 0.6vw solid black;
}

/* SMOKE CONTAINER */
.smoke-container {
  position: absolute;
  top: 21vw;
  left: 51vw;
  pointer-events: none;
  z-index: 2;
  visibility: var(--smoke-visibility);
  opacity: var(--smoke-opacity);
  animation: overallTimeline 6s linear infinite;
}

/* INDIVIDUAL SMOKE */
.smoke {
  position: absolute;
  width: 2vw;
  height: 2vw;
  background: radial-gradient(circle, rgba(128, 128, 128, 0.8) 0%, rgba(128, 128, 128, 0) 70%);
  border-radius: 50%;
  animation: realisticSmoke 1s ease-out infinite;
  filter: blur(0.4vw);
  opacity: 0;
}

.smoke:nth-child(1) {
  width: 2.5vw;
  height: 2.5vw;
  animation-delay: 0s;
}

.smoke:nth-child(2) {
  width: 3vw;
  height: 3vw;
  animation-delay: 0.1s;
}

.smoke:nth-child(3) {
  width: 2vw;
  height: 2vw;
  animation-delay: 0.2s;
}

.smoke:nth-child(4) {
  width: 3.5vw;
  height: 3.5vw;
  animation-delay: 0.15s;
}

.smoke:nth-child(5) {
  width: 2.5vw;
  height: 2.5vw;
  animation-delay: 0.25s;
}

.smoke:nth-child(6) {
  width: 1.5vw;
  height: 1.5vw;
  animation-delay: 0.3s;
}

.smoke:nth-child(7) {
  width: 3vw;
  height: 3vw;
  animation-delay: 0.35s;
}

.smoke:nth-child(8) {
  width: 2.5vw;
  height: 2.5vw;
  animation-delay: 0.4s;
}

.smoke:nth-child(9) {
  width: 2vw;
  height: 2vw;
  animation-delay: 0.45s;
}

.smoke:nth-child(10) {
  width: 3.5vw;
  height: 3.5vw;
  animation-delay: 0.5s;
}

@keyframes realisticSmoke {
  0% {
    opacity: 0.9;
    transform: translateX(0) translateY(0) scale(1);
  }

  15% {
    opacity: 0.8;
    transform: translateX(1vw) translateY(-1vw) scale(1.2);
  }

  30% {
    opacity: 0.7;
    transform: translateX(2.5vw) translateY(-2vw) scale(1.5);
  }

  50% {
    opacity: 0.6;
    transform: translateX(4vw) translateY(-4vw) scale(1.8);
  }

  70% {
    opacity: 0.4;
    transform: translateX(5.5vw) translateY(-6vw) scale(2.1);
  }

  100% {
    opacity: 0;
    transform: translateX(7vw) translateY(-9vw) scale(2.5);
  }
}

/* OVERALL TIMELINE */
@keyframes overallTimeline {
  0% {
    --car-position: 30vw;
    --smoke-opacity: 1;
  }

  10% {
    --car-position: 29.5vw;
  }

  20% {
    --car-position: 29vw;
  }

  30% {
    --car-position: 27vw;
  }

  40% {
    --car-position: 24vw;
  }

  50% {
    --car-position: 18vw;
  }

  60% {
    --car-position: 10vw;
  }

  70% {
    --car-position: 2vw;
  }

  80% {
    --car-position: -6vw;
  }

  90% {
    --car-position: -18vw;
  }

  100% {
    --car-position: -30vw;
    --smoke-opacity: 0;
  }
}


@keyframes move {
  0% {
    left: 0;
  }

  50% {
    left: 50%;
  }

  100% {
    left: 100%;
  }
}

/* MEDIA QUERIES */

@media(max-width: 860px) {
  .car-horizontal {
    top: 55vh;
    width: 80vw;
    height: 80vw;
    z-index: 5;
  }

  .speed-lines {
    top: 60vh;
    /* Changed from 60vw to 60vh */
  }

  .tree {
    top: 16vh;
    left: 5vw;
  }

  .street-light {
    top: 17vh;
    left: 2vw;
  }
}

@media (max-width: 480px) {
  .car-horizontal {
    top: 55vh;
    width: 80vw;
    height: 80vw;
    z-index: 5;
  }

  .speed-lines {
    top: 45vh;
    /* Changed from 95vw to 95vh */
  }

  .road {
    top: 40vh;
    /* Changed from 100vw to 100vh */
    height: 20vh;
  }

  .ground {
    top: 46.3vh;
    /* Changed from 100vw to 100vh */
  }

  .ground-four {
    top: 48.3vh;
    /* Changed from 105vw to 105vh */
  }

  .ground-two {
    top: 60vh;
    /* Changed from 130vw to 130vh */
  }

  .ground-three {
    top: 58vh;
    /* Changed from 135vw to 135vh */
  }

  .sideRoad {
    top: 60vh;
    /* Changed from 135vw to 135vh */
    height: 40vh;
  }

  .sun {
    top: 10vh;
    left: 70vw;
    height: 16vw;
    width: 16vw;

  }

  .cloud {
    top: 12vh;
  }

  .tree {
    top: 33vh;
    left: 5vw;
  }

  .street-light {
    top: 34vh;
    left: 2vw;
  }

  .sky {
    height: 46.3vh;
  }
}
