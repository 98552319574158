body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}

.background {
  // background-image: url('/path/to/your/background-texture.jpg');
  background-color: 'rgba(253, 246, 227, 0.85)'; // semi-transparent overlay
  background-size: cover;
  background-attachment: fixed;
  overflow-y: scroll;
  height: 100%;
}

.scroll-container {
  position: relative;
  z-index: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.scroll-container>div {
  animation: fadeIn 1s ease-out;
}
