.pulseWrapper {
  display: inline-block;
}

.pulsing {
  animation: pulse 0.3s ease;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}